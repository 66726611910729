import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from '@nextui-org/react'
import Image from 'next/image'
import { FC, memo } from 'react'
import BrandLogo from '~/assets/brand-light-mode.svg'

interface Props {}

const Footer: FC<Props> = () => {
  return (
    <footer className="p-4 pt-8 md:p-8 mt-auto text-center">
      <Link href="https://www.trivia-labs.com" target="_blank" rel="noreferrer">
          <Image
            src={BrandLogo}
            width={175}
            height={16}
            className="ml-auto mr-auto"
            alt="brand logo"
          />
      </Link>
      <div className="flex-row justify-center items-center gap-2 sm:flex">
        <span className="text-[#a6a6a6] text-sm">© 2022 0x.watch</span>
        <Link href="https://twitter.com/yesrichbaby" target="_blank" color="text">
          <FontAwesomeIcon width={'16px'} icon={faTwitter} />
        </Link>
        <Link href="https://discord.gg/Hwn5QxxEw3" target="_blank" color="text">
          <FontAwesomeIcon width={'16px'} icon={faDiscord} />
        </Link>
      </div>
    </footer>
  )
}

export default memo(Footer)
