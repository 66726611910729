import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Combobox } from '@headlessui/react'
import { Input, Loading } from '@nextui-org/react'
import cx from 'classnames'
import { ethers } from 'ethers'
import { Dictionary } from 'lodash'
import { keys, omit, pick, take } from 'lodash-es'
import { useRouter } from 'next/router'
import { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react'

function trimHistory(history: Dictionary<number>) {
  return pick(
    history,
    take(
      keys(history).sort((a, b) => history[b] - history[a]),
      10,
    ),
  )
}

const AddressInput: FC<{ onAddressChange: (address: string) => void }> = ({ onAddressChange }) => {
  const router = useRouter()
  const [isSearchingAddress, setIsSearchingAddress] = useState(false)
  const [addressInput, setAddressInput] = useState('')
  const showHistoryBtnRef = useRef<HTMLInputElement>(null)
  const [addressSearchHistory, setAddressSearchHistory] = useState<Dictionary<number>>({})

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const addressSearchHistoryStr = localStorage.getItem('addressSearchHistory')
      if (addressSearchHistoryStr) {
        try {
          let historyMap = JSON.parse(addressSearchHistoryStr)
          if (router.isReady) {            
            const address = router.query.address
            if (address && typeof address === 'string' && address !== 'me') {
              historyMap = { ...historyMap, [address]: Date.now() }
            }
          }
          setAddressSearchHistory(historyMap)
        } catch {}
      }
    }
  }, [setAddressSearchHistory, router.pathname])

  useEffect(() => {
    if (typeof window !== 'undefined' && keys(addressSearchHistory).length) {
      localStorage.setItem(
        'addressSearchHistory',
        JSON.stringify(trimHistory(addressSearchHistory)),
      )
    }
  }, [addressSearchHistory])

  const onAddressInputComplete = useCallback(
    (input: string) => {
      onAddressChange(input)
      setAddressSearchHistory((it) => trimHistory({ ...it, [input]: Date.now() }))
      setAddressInput('')
    },
    [onAddressChange, setAddressSearchHistory],
  )

  const clearHistory = useCallback(
    (addr: string) => {
      if (addr === 'all') {
        setAddressSearchHistory({})
      } else {
        setAddressSearchHistory((it) => omit(it, addr))
      }
    },
    [setAddressInput],
  )

  const handleAddressSearch = useCallback(() => {
    if (!addressInput) {
      return
    }
    if (ethers.utils.isAddress(addressInput) || addressInput.endsWith('.eth')) {
      onAddressInputComplete(addressInput)
    }
  }, [addressInput, onAddressInputComplete])

  return (
    <div className="flex flex-row">
      <form
        className="w-full sm:w-[350px]"
        onSubmit={(e) => {
          e.preventDefault()
          handleAddressSearch()
        }}
      >
        <div className="w-full">
          <Combobox value={addressInput} onChange={setAddressInput} nullable={true}>
            <Combobox.Input onChange={(e) => setAddressInput(e.target.value.trim())} as={Fragment}>
              <Input
                contentClickable={true}
                onContentClick={handleAddressSearch}
                onFocus={() => {
                  showHistoryBtnRef.current?.click()
                }}
                placeholder=" Search by ENS name or Ethereum address"
                fullWidth
                size="sm"
                contentRight={
                  isSearchingAddress ? (
                    <Loading size="xs" />
                  ) : (
                    <FontAwesomeIcon width={'16px'} icon={faSearch} />
                  )
                }
              />
            </Combobox.Input>
            <Combobox.Button className="hidden">
              <div ref={showHistoryBtnRef}></div>
            </Combobox.Button>
            <Combobox.Options as="div" className="absolute z-50 bg-white opacity-90 border">
              {keys(addressSearchHistory)
                .filter((it) => !addressInput || it.includes(addressInput))
                .sort((a, b) => addressSearchHistory[b] - addressSearchHistory[a])
                .map((it) => (
                  <Combobox.Option
                    as="div"
                    key={it}
                    value={it}
                    className={({ active }) =>
                      cx(
                        'cursor-default select-none py-1 px-2 text-sm',
                        active ? 'bg-blue-500 text-white' : 'text-gray-900',
                      )
                    }
                  >
                    {({ active }) => (
                      <div className="flex flex-row justify-between w-[350px]">
                        <div className="max-w-[300px] truncate">{it}</div>
                        {active ? (
                          <div className="flex flex-row gap-2 pl-2 opacity-100">
                            <span
                              className="cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault()
                                clearHistory(it)
                              }}
                            >
                              x
                            </span>
                            <span
                              className="cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault()
                                clearHistory('all')
                              }}
                            >
                              clear
                            </span>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </Combobox.Option>
                ))}
            </Combobox.Options>
          </Combobox>
        </div>
      </form>
    </div>
  )
}

export default AddressInput
