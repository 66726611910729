import { NextPage } from 'next'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useAccount } from 'wagmi'
import AddressInput from '~/components/AddressInput'
import Footer from '~/components/Footer'
import Logo from '~/public/wallet-watch-logo-lg.png'
const ConnectButton = dynamic(import('~/components/Home/ConnectButton'), { ssr: false })

const Index: NextPage = () => {
  const router = useRouter()
  const account = useAccount()
  useEffect(() => {
    if (account?.address) {
      router.replace('/me')
    }
  }, [account?.address, router])
  return (
    <>
      <Head>
        <title>Wallet Watch</title>
      </Head>
      <div className="container mx-auto w-screen h-screen flex flex-col items-center justify-center overflow-hidden">
        <div className="mt-auto flex flex-col items-center">
          <Image src={Logo} width={262} height={86} alt="logo" />
          <div className="mt-5">
            <AddressInput
              onAddressChange={(address) => {
                router.push(`/${address.toLowerCase()}`)
              }}
            />
          </div>
          <div className="mt-8" suppressHydrationWarning={true}>
            <ConnectButton />
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Index
