import { NextUIProvider } from '@nextui-org/react'
import { getDefaultWallets, lightTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import '@rainbow-me/rainbowkit/styles.css'
import type { AppProps } from 'next/app'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { chain, configureChains, createClient, WagmiConfig } from 'wagmi'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import queryClient from '~/api/query-client'
import { ALCHEMY_ID } from '~/utils/consts'
import { Analytics } from '@vercel/analytics/react'

import '../styles/globals.css'

const { chains, provider } = configureChains(
  [chain.mainnet, chain.polygon, chain.optimism, chain.arbitrum],
  [alchemyProvider({ apiKey: ALCHEMY_ID }), publicProvider()],
)
const { connectors } = getDefaultWallets({
  appName: '0x Watch',
  chains,
})

const client = createClient({
  autoConnect: true,
  connectors,
  provider,
})

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <WagmiConfig client={client}>
        <RainbowKitProvider chains={chains} theme={lightTheme({})}>
          <NextUIProvider>
            <QueryClientProvider client={queryClient}>
              <Component {...pageProps} />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </NextUIProvider>
        </RainbowKitProvider>
      </WagmiConfig>
      <Analytics />
    </>
  )
}

export default MyApp
