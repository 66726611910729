export const ALCHEMY_RPC_ENDPOINT =
  'https://eth-mainnet.g.alchemy.com/v2/OrYG__Rx2fVyH9Wx-nb-IFigDYykGrha'

export const ALCHEMY_ID = 'OrYG__Rx2fVyH9Wx-nb-IFigDYykGrha'

export const OPENSEA_API_KEY = '2f6f419a083c46de9d83ce3dbe7db601'

export const ENS_CONTRACT = '0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85'.toLowerCase()

export const CONTRACT_WETH = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
export const CONTRACT_USDT = '0xdac17f958d2ee523a2206206994597c13d831ec7'
export const CONTRACT_USDC = '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
export const CONTRACT_BUSD = '0x4fabb145d64652a948d72533023f6e7a623c7c53'
export const CONTRACT_FRAX = '0x853d955acef822db058eb8505911ed77f175b99e'
export const CONTRACT_DAI = '0x6b175474e89094c44da98b954eedeac495271d0f'
